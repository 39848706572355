import React, { useState, useEffect } from "react";
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";
import DynamicTable from "../../ListView"; 
import NonJoiner from "./addNonJoinersForm"
import { Table } from "antd";
import { getNonJoinerDetails, getNonJoinerEmployee } from "../../../Services/authHeader";
import { PageConfig } from "../../../Services/Context";
import { CompanyWorkFlowConfig } from "../../../Services/companyContext";

const NonJoiners = ({userCompany,showForm, setShowForm}) => {
  const [employees, setEmployees] = useState([]);
  // const [showForm, setShowForm] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const {active,setcloseform}=PageConfig()
  const {workflow_state}=CompanyWorkFlowConfig()
  // const workflow_state=JSON.parse(sessionStorage.getItem("workflow_state"))

  const NonJoinerDetails = async (id) => {
    try {
      const data = await getNonJoinerDetails(id)
    
      setSelectedEmployee(data.data.data);
    } catch (error) {
      console.log("Getting Error in NonJoinerDetails", error)
    }
  }

  
  useEffect(()=>{
   setShowForm(false)
   setcloseform(null)
  },[active])


  async function getEmployees() {
    try {
      const data = await getNonJoinerEmployee();
      const filteredUsers = await data.filter(
        (eachreview) => eachreview.review_added_by_company === userCompany
      );
      // console.log("Data:", data);
      setEmployees(filteredUsers);
    } catch (error) {
      console.log("Error:", error);
    }
  }

  document.body.classList.remove("non-joiners");
  useEffect(() => {
    getEmployees();
  }, []);

  const columns = [
    { title: "Name", dataIndex: "first_name", key:'first_name' },
    { title: "Creation Date", dataIndex: "creation", key:'creation' },
    { title: "Submitted By", dataIndex: "submitted_by", key:'submitted_by' },
    { title: "Joined On", dataIndex: "joined_on", key:'joined_on' },
    { title: "Relieved On", dataIndex: "released_on", key:'released_on' },
    { title: "Designation", dataIndex: "designation", key:'designation' },
    // { title: "Feedback", dataIndex: "type_of_review", key:'type_of_review'},
    {
      title: "Status",
      dataIndex: "workflow_state",
      key: "workflow_state",
      render: (workflow_state) => workflow_state === "Draft" ? "Submitted" : workflow_state,
    },
    {
      title: "Action",
      key: "view",
      render: (_, record) => (
        <button
          onClick={() => handleViewClick(record)}
          className="text-blue-600 hover:underline"
          disabled={!workflow_state}
        >
          View
        </button>
      ),
    },
  ];

  const handleViewClick = async(record) => {
    await NonJoinerDetails(record.name)
    // setSelectedEmployee(record);
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
    setSelectedEmployee(null);
  };

  const handleReset = () => {
    setSelectedEmployee(null);
  };

  const handleFormSubmit = async () => {
    await getEmployees();
    // handleCloseForm();
  };

  return (
    <>
    {showForm ? (
      <NonJoiner
        employee={selectedEmployee}
        onClose={handleCloseForm}
        reset={handleReset}
        onFormSubmit={handleFormSubmit}
        NonJoinerDetails={NonJoinerDetails}
      />
    ) : (
      <Table
        dataSource={employees}
        columns={columns}       
        pagination={{ pageSize: 10 }}
        isNonExEmployee={"non-ex-employee"}
        rowKey="name"
        className="employeeListTable"
      />
    )}
    </>
  );
};

export default NonJoiners;
