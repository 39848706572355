import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Select, Spin } from "antd";
import { LineChartAPI } from "../../Services/authHeader";

const SubscriptionChart = () => {
  const [timeRange, setTimeRange] = useState("Weekly");
  const [graphData, setgraphData] = useState(null)
  const [loadingChart, setloadingChart] = useState(false)


  const GetLineChartData = async (timeStamp) => {
    setloadingChart(true)
    try {
      const data = await LineChartAPI(timeStamp)
      if (data) {
        setgraphData(data)
      }
    } catch (error) {
      console.log("error in Getting line Chart", error)
    } finally {
      setloadingChart(false)
    }
  }

  useEffect(() => {
    GetLineChartData(timeRange.toLowerCase())
  }, [timeRange])



  // Data for different views
  // const dataWeekly = [
  //   { name: "Week 1", uv: 30 },
  //   { name: "Week 2", uv: 45 },
  //   { name: "Week 3", uv: 25 },
  //   { name: "Week 4", uv: 60 },
  // ];

  // const dataMonthly = [
  //   { name: "Jan", uv: 50 },
  //   { name: "Feb", uv: 80 },
  //   { name: "Mar", uv: 45 },
  //   { name: "Apr", uv: 90 },
  //   { name: "May", uv: 75 },
  //   { name: "Jun", uv: 100 },
  //   { name: "Jul", uv: 85 },
  //   { name: "Aug", uv: 95 },
  //   { name: "Sep", uv: 60 },
  //   { name: "Oct", uv: 90 },
  //   { name: "Nov", uv: 80 },
  //   { name: "Dec", uv: 110 },
  // ];

  // const dataYearly = [
  //   { name: "2019", uv: 500 },
  //   { name: "2020", uv: 700 },
  //   { name: "2021", uv: 650 },
  //   { name: "2022", uv: 800 },
  //   { name: "2023", uv: 750 },
  // ];

  const handleChange = (value) => {
    setTimeRange(value);
  };

  let chartData;

  switch (timeRange) {
    case "Weekly":
      chartData = graphData;
      break;
    case "Monthly":
      chartData = graphData;
      break;
    case "Yearly":
      chartData = graphData;
      break;
    default:
      chartData = graphData;
  }

  return (
    <div className="subscription-chart">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h2>Subscription</h2>
        <Select defaultValue="Weekly" style={{ width: 120 }} onChange={handleChange}>
          <Select.Option value="Weekly">Weekly</Select.Option>
          <Select.Option value="Monthly">Monthly</Select.Option>
          <Select.Option value="Yearly">Yearly</Select.Option>
        </Select>
      </div>
      <Spin spinning={loadingChart}>
        <ResponsiveContainer width="100%" height={300}>

          <LineChart
            data={chartData}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="uv" stroke="#8884d8" />
          </LineChart>

        </ResponsiveContainer>
      </Spin>
    </div>
  );
};

export default SubscriptionChart;
