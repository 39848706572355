import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { TextField } from "@material-ui/core";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoginRight from "./LoginRight";
import LoginLeft from "./LoginLeft";
import { forgotPassword, verifyOTP } from "../../Services/authHeader";

const ConfirmOtp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [resendEnabled, setResendEnabled] = useState(false);
  const [timer, setTimer] = useState(120);
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email");

  useEffect(() => {
    if (timer > 0) {
      const countdown = setTimeout(() => setTimer(timer - 1), 1000);
      return () => clearTimeout(countdown);
    } else {
      setResendEnabled(true);
    }
  }, [timer]);

  const handResendOTPFunction = async () => {
    try {
      const response = await forgotPassword(email);
      if (response.message === 'OTP has been sent to your email account.') {
    
        toast.success(response.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
      } else {

        toast.error("Failed to send OTP. Please try again.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

    } catch (error) {
      const errorMessage =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        "Failed to send OTP. Please try again.";
      toast.error(errorMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

  }

  const handleResendOTP = () => {
    if (!resendEnabled) return;
    if(!email) return
    setTimer(120);
    setResendEnabled(false);
    handResendOTPFunction()

  };

  const ValidateOTP = Yup.object().shape({
    otp: Yup.string()
      .required("Please enter the OTP ")
      .length(6, "OTP must be 6 digits"),
  });

  const formik = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: ValidateOTP,
    onSubmit: async (values) => {
      try {
        if (!email) {
          toast.error("Email is required to verify OTP");
          return;
        }

        const response = await verifyOTP(email, values.otp);
        if (response.message.success === 1) {
          toast.success("OTP verified successfully");
          setTimeout(() => {
            navigate(`/NewPassword?email=${email}`);
          }, 3000);
        } else {
          toast.error("Failed to verify OTP");
        }
      } catch (error) {
        if(error?.response?.data?.message["message "]=== "Please enter valid OTP"){
          toast.error("Please enter valid OTP");
        }else{
          toast.error("Error verifying OTP");
          console.error("Error verifying OTP:", error);
        }
      }
    },
  });

  return (
    <>
      <section className="flex m-auto container-fluid w-full h-screen login-section confirm-otp">
        <LoginLeft>
          <h1>Confirm OTP</h1>
          <form
            onSubmit={formik.handleSubmit}
            className="space-y-4 md:space-y-6"
            action="#"
          >
            <div>
              <TextField
                label="Confirm OTP"
                variant="outlined"
                type={"text"}
                value={formik.values.otp}
                name="otp"
                onChange={formik.handleChange}
                id="otp"
                placeholder="Enter OTP"
                required=""
              />
              <p style={{ color: "red" }}>
                {formik.errors.otp ? formik.errors.otp : ""}
              </p>
            </div>
            <a
              className={`resend-otp mt-3 float-right ${resendEnabled ? "" : "disabled-link"
                }`}
              href="#"
              onClick={handleResendOTP}
            >
              {resendEnabled ? "Resend OTP" : `Resend OTP in ${timer}s`}
            </a>
            <button type="submit" className="auth-btn">
              Verify OTP
            </button>
          </form>
        </LoginLeft>
        <LoginRight />
        <ToastContainer />
      </section>


      <style>{`
        .disabled-link {
          pointer-events: none;
          opacity: 0.5;
        }
      `}</style>
    </>
  );
};

export default ConfirmOtp;





