import React, { useCallback, useEffect, useState, memo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Layout, Table, Tabs } from "antd";
import Dashboardnavbar from "../../Widgets/Dashboardnavbar";
import CommonNavbar from "../../Widgets/CommonNavbar";
import { Content } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import Pointhistory from "../../Assets/Images/point-history.png";
import DonutChart from "./DonutChart";
import AuthHeader, {
  getExEmployee,
  getNonJoinerEmployee,
  getPointsHistoryAndGraphData,
  getrecentlyAddedReviewsData,
  getrecentlySearchedReviewsData,
  getRecentlySearchedUser,
  getSubscriptionHistoryByCompany,
  getUserCompany,
  searchUsers,
} from "../../Services/authHeader";
import { getCompanyDetails } from "../../Services/superadminService";
import { CompanyWorkFlowConfig } from "../../Services/companyContext";

const { TabPane } = Tabs;

const Home = () => {
  const [earnedPoints, setEarnedPoints] = useState(null);
  const [userData, setUserData] = useState(null);
  const getUserName = sessionStorage.getItem("name");
  const [tabType, setTabType] = useState("ExEmployee");
  const [formType, setFormType] = useState(null);
  const navigate = useNavigate();
  const [showButton, setShowButton] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [recentlySearchedReviewsData, setRecentlySearchedReviewsData] =
    useState([]);
  const [recentlyAddedReviewsData, setRecentlyAddedReviewsData] = useState([]);
  const [filter, setFilter] = useState("all");
  const [nonJoiners, setNonJoiners] = useState([]);
  const [exEmployees, setExEmployees] = useState([]);
  const [activeTab, setActiveTab] = useState("exEmployees");
  const [getUser, setGetUser] = useState(null);
  const [GraphData, setGraphData] = useState({})


  // const workflow_state = JSON.parse(sessionStorage.getItem("workflow_state"))
  const {workflow_state}=CompanyWorkFlowConfig()




  let getLoginUserName = sessionStorage.getItem("usr");
  if (getLoginUserName) {
    try {
      const parsedUser = JSON.parse(getLoginUserName);
      getLoginUserName = parsedUser.username;
    } catch (error) {
      console.error("Error parsing user data:", error);
      getLoginUserName = null; // or some default value
    }
  }
  useEffect(() => {
    // Retrieve user data from session storage
    const storedUser = sessionStorage.getItem("usr");
    if (storedUser) {
      let temp = JSON.parse(storedUser);
      setGetUser(JSON.parse(storedUser));
      fetchRecentlySearchedReviews(temp);
    }
  }, []);
  const fetchRecentlySearchedReviews = async (temp) => {
    try {
      const email = temp.email;
      const res = await getUserCompany(email);

      const GraphData = await getPointsHistoryAndGraphData(res.data.data)
      //const companyDetails = await getCompanyDetails(res.data.data.company)
      //sessionStorage.setItem("workflow_state", companyDetails.workflow_state === "Approved" ? true : false)
      setGraphData(GraphData)
      const data = await getRecentlySearchedUser(res.data.data.company);
      //const filteredData = data.filter((user) => user.doctype !== "User");

      setRecentlySearchedReviewsData(data.data.message.result);
      if(res){
        sessionStorage.setItem('company',res?.data?.data?.company)
      }
    } catch (error) {
      console.error("Error fetching Recently Searched Reviews:", error);
    }
  };
  // console.log("recentlySearchedReviewsData",recentlySearchedReviewsData)
  // useEffect(() => {}, []); // Empty dependency array to fetch on component mount

  const columns1 = [
    { title: "User Name", dataIndex: "user_name", key: "user_name" },
    {
      title: "Date",
      dataIndex: "creation",
      key: "creation",
      render: (date) => formatDateToDDMMYYYY(date),
      className: "table-date",
    },
  ];

  const fetchNonJoiners = useCallback(async () => {
    try {
      const data = await getNonJoinerEmployee();
      // console.log("Non Joiner Data:", data);
      setNonJoiners(data);
    } catch (error) {
      console.log("Error:", error);
    }
  }, []);

  const fetchExEmployees = useCallback(async () => {
    try {
      const data = await getExEmployee();
      // console.log("Ex Employees Data:", data);
      setExEmployees(data);
    } catch (error) {
      console.log("Error:", error);
    }
  }, []);

  useEffect(() => {
    // Initially fetch the Ex-Employees data
    fetchExEmployees();
  }, []);

  const editableForm = () => {
    if (tabType === "ExEmployee" || tabType === "NonJoiner") {
      setFormType(tabType);
    }
  };

  const handleCloseButton = () => {
    setShowButton(true);
  };

  document.body.classList.remove(
    "billing-page",
    "profile-page",
    "billing-page",
    "search-page",
    "users-page",
    "review-page"
  );
  document.body.classList.add("dashboard-page");

  const columns = [
    { title: "User Name", dataIndex: "first_name", key: "first_name" },
    { title: "Date", dataIndex: "date", key: "date", className: "table-date" },
  ];

  const formatDateToDDMMYYYY = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const filteredData = activeTab === "nonJoiners" ? nonJoiners : exEmployees;

  filteredData.forEach((user) => {
    user.date = formatDateToDDMMYYYY(user.creation);
  });

  const data = [
    { name: "Searched", value: GraphData?.total_search },
    { name: "Submitted", value: GraphData?.total_submit },
  ];

  const handleTabChange = useCallback(
    (key) => {
      // console.log("Tab changed to:", key);
      setActiveTab(key);
      if (key === "nonJoiners" && nonJoiners.length === 0) {
        fetchNonJoiners();
      } else if (key === "exEmployees" && exEmployees.length === 0) {
        fetchExEmployees();
      }
    },
    [nonJoiners, exEmployees, fetchNonJoiners, fetchExEmployees]
  );

  const MemoizedTabs = memo(({ handleTabChange,activeTab }) => (
    <Tabs activeKey={activeTab} onChange={handleTabChange}>
      <TabPane tab="Ex-Employees" key="exEmployees" />
      <TabPane tab="Non-Joiners" key="nonJoiners" />
    </Tabs>
  ));



  return (
    <Layout className="h-screen">
      <Sider width={256} className="site-layout-background h-full">
        <Dashboardnavbar />
      </Sider>
      <Layout className="panel-right">
        <CommonNavbar />
        <Content className="dashboard-layout">
          <div className="heading-home">
            <h2 className="text-xl dashboard-home-text">
              Welcome, {getUserName || getLoginUserName}
            </h2>
          </div>

          {/* Card for table */}
          <div className="w-auto home-section">
            <table className="w-full">
              {/* Recently Add Reviews  */}
              <div className="dashboard-row">
                <div className="dashboard-blocks">
                  <div className="dashboard-header">
                    <h2>Recently Added Reviews</h2>
                    <div className="toggle-buttons">
                      <MemoizedTabs handleTabChange={handleTabChange} activeTab={activeTab}/>
                    </div>
                  </div>
                  <Table
                    dataSource={filteredData.slice(0, 7)}
                    columns={columns}
                    pagination={false}
                  />
                  <Link
                    to={workflow_state ? "/Reviews" : "/HomeDashboard"} className="view-all"
                  >
                    View All
                  </Link>
                </div>
                <div className="dashboard-blocks">
                  <div className="dashboard-header">
                    <h2>Recently Searched Reviews</h2>
                  </div>
                  <Table
                    dataSource={recentlySearchedReviewsData?.slice(0, 7)}
                    columns={columns1}
                    pagination={false}
                  />
                  <Link
                    // to={workflow_state ? "/Search" : "/HomeDashboard"}  className="view-all"
                    to={workflow_state ? "/Search" : "/HomeDashboard"}
                    state={{ recentSearchData: recentlySearchedReviewsData }}
                    className="view-all"

                  >
                    View All
                  </Link>
                </div>
              </div>
              {/* Target achevments  */}
              <div className="dashboard-row">
                <div className="dashboard-blocks">
                  <div className="dashboard-header">
                    <h2>Target Achievement</h2>
                  </div>
                  <div className="blocks-body">
                    <DonutChart data={data} />
                  </div>
                </div>
                {/* for coin add */}

                <div className="dashboard-blocks">
                  <div className="dashboard-header">
                    <h2>Points History</h2>
                    <span>Pending Points : {Object.keys(GraphData).length !== 0 ? GraphData?.pending_points : 0}</span>
                  </div>
                  <div className="blocks-body">
                    <div className="point-history flex p-11">
                      <div className="w-1/2">
                        <h5 className="pb-1">Total Points</h5>
                        <h4 className="pb-9">{Object.keys(GraphData).length !== 0 ? GraphData?.total_subscribed_points : 0}</h4>
                        <h5 className="pb-1">Points spent</h5>
                        <h4 className="pb-9">{Object.keys(GraphData).length !== 0 ? GraphData?.point_spent : 0}</h4>
                        <h5 className="pb-1">Reviews Given</h5>
                        <h4>{Object.keys(GraphData).length !== 0 ? GraphData?.reviews_given : 0}</h4>
                      </div>
                      <div className="w-1/2">
                        <img
                          src={Pointhistory}
                          alt=""
                          className="point-img"
                        ></img>
                      </div>
                    </div>
                  </div>
                  {/* f */}
                </div>
              </div>
            </table>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Home;
