import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import AuthHeader, {
  getPoints,
  getUser,
  getUserDetails,
  ProfileData,
} from "../Services/authHeader";
import { useNavigate } from "react-router-dom";
import User from "../Assets/Images/user-icon.png";
import Prizes from "../Assets/Images/prize.png";
import Image from "../Assets/Images/close.png"; // updated path for the image
import Adminusericon from "../Assets/Images/admin-user-icon.png";
import "../superadmin.css";
import { getUsers } from "../Services/authHeader";
import { Spin } from "antd";

function CommonNavbar({ reconfig }) {
  const [isOpen, setIsOpen] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showLogoutMenu, setShowLogoutMenu] = useState(false);
  const [getUser, setGetUser] = useState();
  const [profileData, setProfileData] = useState();

  const navigate = useNavigate();
  const [userList, setUserList] = useState([]);
  const [userDetails, setUserDetails] = useState(null);
  // const [loading, setLoading] = useState(true);
  const [Points, setPoints] = useState(0);
  const [loadingProfile, setloadingProfile] = useState(false)

  const Url = process.env.REACT_APP_API_URL;
  const addUser = Yup.object().shape({
    email: Yup.string().required("Please enter your email"),
    first_name: Yup.string().required("Enter your first name"),
    company: Yup.string().required("Enter your company name"),
    phone: Yup.string().required("Please enter your mobile number"),
    password: Yup.string().required("Please enter password"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      first_name: "",
      company: "",
      phone: "",
      password: "",
    },
    validationSchema: addUser,
    onSubmit: (values) => {
      addReviewUser(values);
      navigate("/data");
    },
  });

  const handleToggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  const handleToggleLogoutMenu = () => {
    setShowLogoutMenu(!showLogoutMenu);
  };

  const handleClose = () => {
    setIsOpen(false);
    const darkModeElement = document.getElementById("dark-mode-on");
    if (darkModeElement) {
      darkModeElement.classList.remove("dark-mode-on");
    }
  };

  async function addReviewUser(values) {
    console.log(values);
    try {
      await AuthHeader.addUser(
        values.email,
        values.first_name,
        values.phone,
        values.company,
        values.password
      );
    } catch (error) {
      console.log("error", error);
    }
  }

  // to get the user name and role (image not available rn)
  useEffect(() => {
    const storedData = sessionStorage.getItem("usr");
    if (storedData) {
      setGetUser(JSON.parse(storedData));
    }
  }, []);

  const fetchProfileData = async () => {
    setloadingProfile(true)
    try {
      const data = await ProfileData(getUser.email);
      // console.log("fetchProfileData",data);
      setProfileData(data);
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
    finally {
      setloadingProfile(false)
    }
  };

  useEffect(() => {
    if (getUser) {
      fetchProfileData();
    }
  }, [getUser, reconfig]);



  // ---------------------------IntegratePoints----------------------------
  useEffect(() => {
    if (profileData) {
      getPoints(profileData?.company).then((res) => {
        setPoints(res?.total_balance_points)
      })
    }
  }, [profileData, reconfig])

  const clearAllCookies = () => {
    const cookies = document.cookie.split("; ");

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
    }

    console.log("All cookies cleared");
  };





  return (
    <>
      <nav className="admin-top-nav">
        <div className="page-heading">
          {window.location.pathname.split("/")[1] === 'HomeDashboard' ? "Home Dashboard" : window.location.pathname.split("/")[1]}
        </div>
        {/* Toggle button for mobile */}
        <button
          onClick={handleToggleMobileMenu}
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-900 dark:focus:ring-gray-900 admin-toggle"
          aria-expanded={showMobileMenu}
        >
          <span className="sr-only">Toggle Menu</span>
          <svg
            className={`w-5 h-5 ${showMobileMenu ? "hidden" : ""}`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
          <svg
            className={`w-5 h-5 ${showMobileMenu ? "" : "hidden"}`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        {/* Menu items */}
        <div
          className={`md:flex md:items-center toggle-nav md:ml-auto transition-transform ${showMobileMenu ? "block" : "hidden"
            }`}
        >
          <div className="nav-btns points-count">
            <img src={Prizes} alt="" />
            {Points ? Points : 0} Points
          </div>
          <div className="dropdown">
            <button
              className="flex items-center super-admin-logout space-x-8 pr-4 h-13 border border-gray-300 rounded-full py-1"
              onClick={handleToggleLogoutMenu}
            >
              <Spin spinning={loadingProfile}>
                <img
                  className="Adminusericon rounded-full"
                  src={profileData?.user_image ? (profileData?.user_image.startsWith("http") ? profileData?.user_image : Url +
                    profileData?.user_image) : `https://ui-avatars.com/api/?name=${profileData?.first_name}&rounded=true&background=random`
                  }
                  alt=""
                />
              </Spin>
              <h3 className="block admin-name-txt">
                {profileData?.full_name}
                {/* <span className="block admin-txt">
                  {profileData?.role_profile_name}
                </span> */}
              </h3>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 14L8 10H16L12 14Z"
                  fill="#647086"
                  stroke="#647086"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            {/* dropdown menu */}
            <div
              className={`z-10 w-40 h-auto mt-4 bg-white absolute dropdown-logout rounded-full divide-y divide-gray-100 shadow ${showLogoutMenu ? "block" : "hidden"
                }`}
            >
              <Link
                to="/Logout"
                className="block py-1 px-2 text-left logout-link"
              >
                Logout
              </Link>
            </div>
          </div>
        </div>
      </nav >
    </>
  );
}

export default CommonNavbar;
