import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { LogoutUser } from '../../Services/authHeader';

const Logout = () => {
  const navigate = useNavigate()

  const clearAllCookies = () => {
    const cookies = document.cookie.split("; ");
    
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
    }
    
    console.log("All cookies cleared");
  };

  const handleLogout = async() => {
    await LogoutUser()
    // console.log("####Logout## Function call",logout)
    await sessionStorage.clear()
    await clearAllCookies()
    await navigate('/Login')
  }
  useEffect(() => {
    handleLogout()
  })
  return (
    <div>logout</div>
  )
}

export default Logout