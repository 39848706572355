import React, { createContext, useContext, useState, useRef, useEffect } from 'react'
import { getCompanyDetails } from './superadminService';
import { getUserCompany } from './authHeader';

export const WorkFlowContext = createContext();

export const WorkflowContextProvider = ({ children }) => {
    const [WorkFlowCompanyData, setWorkFlowCompanyData] = useState([]);
    const [billingIndiCator,setbillingIndiCator]=useState(false)
    const companyDataRef = useRef(null);  

    const GettingCompanyDetails = async () => {
        const storedUser = JSON.parse(sessionStorage.getItem("usr"));
        try {
            const res = await getUserCompany(storedUser?.email); 
            const companyDetails = await getCompanyDetails(res.data.data.company);
            setWorkFlowCompanyData(companyDetails);
            companyDataRef.current = companyDetails; 
        } catch (error) {
            console.log("error in GettingCompanyDetails", error);
        }
    };

    useEffect(() => {
        GettingCompanyDetails(); // Fetch company details on mount
    }, []);  // Empty dependency array to call it only once

   
    // const workflow_state=WorkFlowCompanyData?.workflow_state === "Approved" ? true : false
    const workflow_state=true
    

    return (
        <WorkFlowContext.Provider value={{ WorkFlowCompanyData, companyDataRef,workflow_state,billingIndiCator,setbillingIndiCator}}>
            {children}
        </WorkFlowContext.Provider>
    );
};

export const CompanyWorkFlowConfig = () => {
    return useContext(WorkFlowContext);
};


