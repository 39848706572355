import React, { useState } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import "bootstrap-icons/font/bootstrap-icons.css"
import logoWhite from "../Assets/Images/white.png";
import toggleicon from "../Assets/Images/toogle-icon.png";
import ToggleSideBar from "../Assets/Images/sidebar-toggle.svg";
import { PageConfig } from "../Services/Context";
function Dashboardnavbar() {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const {setactive,setcloseform }=PageConfig()

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };


  const handleClickReview = (e) => {
    if (location.pathname === '/Reviews') {
      e.preventDefault(); 
      setactive((prev)=>!prev)
      setcloseform("close") 
      navigate('/Reviews')
    } 
  };
  const handleClickSearch=(e)=>{
    if(location.pathname === '/Search'){
      e.preventDefault(); 
      setactive((prev)=>!prev)
      navigate('/Search')
    }
  }



  return (
    <>
      <img class="w-8 h-8 " src={toggleicon} alt="" className="sidebar-logo" />
      <button
        onClick={toggleMenu}
        data-drawer-target="logo-sidebar"
        data-drawer-toggle="logo-sidebar"
        aria-controls="logo-sidebar"
        type="button"
        class="inline-flex items-center mt-2 ml-2 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 sidebar-logo"
      >
        <span class="sr-only">Open sidebar</span>
        <img
          class="w-8 h-8 "
          src={ToggleSideBar}
          alt=""
          style={{
            filter: "invert(1) brightness(1000%)",
          }}
        />
      </button>
      <div
        id="logo-sidebar"
        className={`top-0 left-0 z-40 w-64 h-full transition-transform ${isMenuOpen ? "translate-x-0" : "-translate-x-full"
          } lg:translate-x-0`}
        aria-label="Sidebar"
      >
        <div class="h-screen px-3 py-4 overflow-y-auto bg-gray-900 dark:bg-gray-800 ">
          <>
            <button
              onClick={toggleMenu}
              type="button"
              data-drawer-hide="drawer-navigation"
              aria-controls="drawer-navigation"
              class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white close-btn"
            >
              <svg
                aria-hidden="true"
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span class="sr-only">Close menu</span>
            </button>
            <Link
              to="/HomeDashboard"
              className="flex items-center p-2 text-base font-normal group"
            >
              <img
                src={logoWhite}
                className="mr-3 h-5 sm:h-9"
                alt="Flowbite Logo"
                class="white-logo"
              />
            </Link>
            <div className="side-navbar pt-4">
              <ul class="space-y-2 font-medium">
                <li className="dash-nav-link">
                  <Link
                    to="/HomeDashboard"
                    className="flex items-center p-2 HomeDashboard text-base font-normal text-white rounded-lg hover:text-gray-900 hover:bg-white dark:hover:bg-gray-700 group"
                  >
                    <svg
                      width="23"
                      height="23"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      id="home"
                    >
                      <path
                        d="M10.3905 1.1464L1.24699 8.98368L1.15198 9.06512L1.05718 9.14638L0.918651 9.26512L0.875 9.30253V9.36003V9.54169V9.66669V9.79169V18.875V19V19.125H0.878676C0.909179 19.643 1.12845 20.1337 1.4974 20.5026C1.86635 20.8716 2.357 21.0908 2.875 21.1213V21.125H3H3.125H8.20833H8.33333H8.45833V21.1151C8.62198 21.0889 8.77445 21.0118 8.89313 20.8931C9.0118 20.7745 9.08891 20.622 9.11507 20.4583H9.125V20.3333V20.2083V16.4583V16.3333C9.125 15.8361 9.32254 15.3591 9.67418 15.0075C10.0258 14.6559 10.5027 14.4583 11 14.4583C11.4973 14.4583 11.9742 14.6559 12.3258 15.0075C12.6775 15.3591 12.875 15.8361 12.875 16.3333V16.4583V20.2083V20.3333V20.4583H12.8849C12.9111 20.622 12.9882 20.7745 13.1069 20.8931C13.2255 21.0118 13.378 21.0889 13.5417 21.1151V21.125H13.6667H13.7917H18.875H19H19.125V21.1213C19.643 21.0908 20.1336 20.8716 20.5026 20.5026C20.8716 20.1337 21.0908 19.643 21.1213 19.125H21.125V19V18.875V9.79169V9.66669V9.54169V9.36003V9.30253L21.0813 9.26512L20.9428 9.14638L20.848 9.06512L20.753 8.98368L11.6095 1.1464L11.6096 1.1463L11.5147 1.06514L11.5146 1.06505C11.5145 1.06498 11.5144 1.0649 11.5143 1.06483L11.4197 0.983699L11.4131 0.991344C11.2894 0.91568 11.1466 0.875 11 0.875C10.8534 0.875 10.7106 0.91568 10.5869 0.991344L10.5803 0.983699L10.4854 1.06505L10.4853 1.06514L10.3904 1.1463L10.3905 1.1464ZM11 2.70944L19.5417 10.0308V18.875V19C19.5417 19.1437 19.4846 19.2814 19.383 19.383C19.2814 19.4846 19.1437 19.5417 19 19.5417H18.875H14.4583V16.4583V16.3333V16.2083H14.4561C14.4246 15.3365 14.0645 14.507 13.4454 13.8879C12.8264 13.2689 11.9968 12.9088 11.125 12.8773V12.875H11H10.875V12.8773C10.0032 12.9088 9.17363 13.2689 8.55459 13.8879C7.93555 14.507 7.57543 15.3365 7.54392 16.2083H7.54167V16.3333V16.4583V19.5417H3.125H3C2.85634 19.5417 2.71857 19.4846 2.61698 19.383C2.5154 19.2814 2.45833 19.1437 2.45833 19V18.875V10.0308L11 2.70944Z"
                        fill="#68859B"
                        stroke="#68859B"
                        stroke-width="0.25"
                      />
                    </svg>
                    <span className="flex-1  text-left whitespace-nowrap">
                      Home
                    </span>
                  </Link>
                </li>
                <li className="dash-nav-link">
                  <Link
                    to="/Profile"
                    className="flex items-center Profile p-2 text-base font-normal text-white rounded-lg hover:text-gray-800 hover:bg-white dark:hover:bg-gray-700 group"
                  >
                    <svg
                      width="23"
                      height="25"
                      viewBox="0 0 23 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      id="profile"
                    >
                      <path
                        d="M21.0413 22.0447L20.3342 21.3376L21.0413 22.0447C21.6547 21.4312 21.9994 20.5993 21.9994 19.7318C21.9994 18.262 21.4155 16.8524 20.3762 15.8131C19.3369 14.7738 17.9273 14.1899 16.4575 14.1899H7.37386C5.90408 14.1899 4.49449 14.7738 3.4552 15.8131C2.4159 16.8524 1.83203 18.262 1.83203 19.7318C1.83203 20.5993 2.17664 21.4312 2.79006 22.0447C3.40348 22.6581 4.23545 23.0027 5.10295 23.0027H18.7284C19.5959 23.0027 20.4279 22.6581 21.0413 22.0447Z"
                        stroke="#68859B"
                        stroke-width="2"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11.9161 10.6467C14.3497 10.6467 16.3225 8.67393 16.3225 6.24036C16.3225 3.80679 14.3497 1.83398 11.9161 1.83398C9.48257 1.83398 7.50977 3.80679 7.50977 6.24036C7.50977 8.67393 9.48257 10.6467 11.9161 10.6467Z"
                        stroke="#68859B"
                        stroke-width="2"
                      />
                    </svg>
                    <span className="flex-1  text-left whitespace-nowrap">
                      Profile
                    </span>
                  </Link>
                </li>
                <li className="dash-nav-link">
                  <Link
                    to="/Reviews"
                    className="flex items-center database p-2 text-base font-normal text-white rounded-lg hover:text-gray-900 hover:bg-white dark:hover:bg-gray-700 group"
                    onClick={handleClickReview}
                  >
                    <svg
                      width="23"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      id="database"
                    >
                      <path
                        d="M2.22222 10.5C2.22222 10.8459 2.73444 11.4483 3.92222 12.0396C5.46 12.8045 7.64111 13.2632 10 13.2632C12.3589 13.2632 14.54 12.8045 16.0778 12.0396C17.2656 11.4483 17.7778 10.8459 17.7778 10.5V8.10047C15.9444 9.22784 13.1411 9.94737 10 9.94737C6.85889 9.94737 4.05556 9.22674 2.22222 8.10047V10.5ZM17.7778 13.6268C15.9444 14.7542 13.1411 15.4737 10 15.4737C6.85889 15.4737 4.05556 14.7531 2.22222 13.6268V16.0263C2.22222 16.3723 2.73444 16.9746 3.92222 17.5659C5.46 18.3308 7.64111 18.7895 10 18.7895C12.3589 18.7895 14.54 18.3308 16.0778 17.5659C17.2656 16.9746 17.7778 16.3723 17.7778 16.0263V13.6268ZM0 16.0263V4.97368C0 2.22711 4.47778 0 10 0C15.5222 0 20 2.22711 20 4.97368V16.0263C20 18.7729 15.5222 21 10 21C4.47778 21 0 18.7729 0 16.0263ZM10 7.73684C12.3589 7.73684 14.54 7.27816 16.0778 6.51332C17.2656 5.922 17.7778 5.31963 17.7778 4.97368C17.7778 4.62774 17.2656 4.02537 16.0778 3.43405C14.54 2.66921 12.3589 2.21053 10 2.21053C7.64111 2.21053 5.46 2.66921 3.92222 3.43405C2.73444 4.02537 2.22222 4.62774 2.22222 4.97368C2.22222 5.31963 2.73444 5.922 3.92222 6.51332C5.46 7.27816 7.64111 7.73684 10 7.73684Z"
                        fill="#68859B"
                      />
                    </svg>
                    <span className="flex-1 whitespace-nowrap">
                      Reviews
                    </span>
                  </Link>
                </li>
                <li className="dash-nav-link">
                  <Link
                    to="/Users"
                    className="flex Users items-center p-2 text-base font-normal text-white rounded-lg hover:text-gray-900 hover:bg-white dark:hover:bg-gray-700 group"
                  >
                    <svg
                      width="23"
                      height="21"
                      viewBox="0 0 23 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20.14 18.1498L19.9917 18.1727L20.14 18.1498C20.1223 18.0354 20.0817 17.9258 20.0207 17.8272C18.6689 15.5256 16.6145 13.8479 14.2107 12.9954C15.3433 12.2562 16.2247 11.1941 16.7342 9.94708C17.2841 8.60124 17.3703 7.11465 16.9797 5.71574C16.589 4.31686 15.7432 3.08342 14.5725 2.20458C13.4019 1.32576 11.9712 0.85 10.5 0.85C9.02882 0.85 7.59806 1.32576 6.42746 2.20458C5.25683 3.08342 4.41099 4.31686 4.02034 5.71574C3.62968 7.11465 3.71595 8.60124 4.26582 9.94708C4.77532 11.1941 5.6566 12.2561 6.78922 12.9953C4.38541 13.847 2.33109 15.5248 0.979299 17.8272C0.918267 17.9258 0.877693 18.0354 0.860045 18.1498C0.842293 18.2648 0.848115 18.3822 0.877152 18.495C0.906187 18.6078 0.957822 18.7135 1.0289 18.8061C1.09997 18.8986 1.18902 18.9761 1.29072 19.0339C1.39241 19.0918 1.50472 19.129 1.62104 19.1433C1.73735 19.1576 1.85541 19.1489 1.96829 19.1175C2.08116 19.0862 2.18666 19.0329 2.27851 18.9606C2.3698 18.8887 2.44579 18.7996 2.50197 18.6983C4.19475 15.8174 7.18415 14.0989 10.5 14.0989C13.8158 14.0989 16.8052 15.8174 18.498 18.6982C18.5542 18.7996 18.6302 18.8887 18.7215 18.9606C18.8133 19.0329 18.9188 19.0862 19.0317 19.1176C19.1446 19.1489 19.2626 19.1576 19.379 19.1433C19.4953 19.129 19.6076 19.0918 19.7093 19.0339C19.811 18.9761 19.9 18.8986 19.9711 18.8061C20.0422 18.7135 20.0938 18.6078 20.1228 18.495C20.1519 18.3822 20.1577 18.2649 20.14 18.1498ZM5.53955 7.4755C5.53958 6.50995 5.83021 5.56591 6.37495 4.76272C6.91975 3.95945 7.69433 3.33305 8.60099 2.96307C9.50767 2.59307 10.5055 2.49623 11.4682 2.68488C12.4308 2.87353 13.3148 3.33912 14.0084 4.02243C14.7019 4.70572 15.174 5.57601 15.3652 6.52312C15.5564 7.47022 15.4583 8.45194 15.0832 9.34423C14.708 10.2365 14.0725 10.9996 13.2567 11.5366C12.441 12.0736 11.4817 12.3604 10.5002 12.3604C9.18424 12.359 7.92306 11.8434 6.99324 10.9273C6.06349 10.0113 5.54095 8.7699 5.53955 7.4755Z"
                        fill="#68859B"
                      />
                    </svg>

                    <span className="flex-1  text-left whitespace-nowrap">
                      User
                    </span>
                  </Link>
                </li>
                <li className="dash-nav-link">
                  <Link
                    to="/Billing"
                    className="flex Billing items-center p-2 text-base font-normal text-white rounded-lg hover:text-gray-900 hover:bg-white dark:hover:bg-gray-700 group"
                  >
                    <svg
                      width="23"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      id="billing"
                    >
                      <path
                        d="M17 20H3C2.20435 20 1.44129 19.6839 0.87868 19.1213C0.316071 18.5587 0 17.7956 0 17V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H15C15.2652 0 15.5196 0.105357 15.7071 0.292893C15.8946 0.48043 16 0.734784 16 1V13H20V17C20 17.7956 19.6839 18.5587 19.1213 19.1213C18.5587 19.6839 17.7956 20 17 20ZM16 15V17C16 17.2652 16.1054 17.5196 16.2929 17.7071C16.4804 17.8946 16.7348 18 17 18C17.2652 18 17.5196 17.8946 17.7071 17.7071C17.8946 17.5196 18 17.2652 18 17V15H16ZM14 18V2H2V17C2 17.2652 2.10536 17.5196 2.29289 17.7071C2.48043 17.8946 2.73478 18 3 18H14ZM4 5H12V7H4V5ZM4 9H12V11H4V9ZM4 13H9V15H4V13Z"
                        fill="#68859B"
                      />
                    </svg>
                    <span className="flex-1  text-left whitespace-nowrap">
                      Billing
                    </span>
                  </Link>
                </li>
                <li className="dash-nav-link">
                  <Link
                    to="/Search"
                    className="flex Search items-center p-2 text-base font-normal text-white rounded-lg hover:text-gray-900 hover:bg-white dark:hover:bg-gray-700 group"
                    onClick={handleClickSearch}
                  >
                    <svg
                      width="23"
                      height="23"
                      viewBox="0 0 23 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      id="search"
                    >
                      <path
                        d="M4.19521 4.19424L4.90232 4.90134L4.19521 4.19424C3.4463 4.94315 2.85223 5.83224 2.44693 6.81073C2.04162 7.78923 1.83301 8.83798 1.83301 9.8971C1.83301 10.9562 2.04162 12.005 2.44693 12.9835C2.85223 13.962 3.4463 14.8511 4.19521 15.6C4.94412 16.3489 5.83321 16.943 6.81171 17.3483C7.79021 17.7536 8.83896 17.9622 9.89808 17.9622C10.9572 17.9622 12.006 17.7536 12.9845 17.3483C13.6535 17.0712 14.2807 16.7058 14.8498 16.2631L20.2932 21.7065C20.6838 22.097 21.3169 22.097 21.7074 21.7065C22.098 21.3159 22.098 20.6828 21.7074 20.2923L16.264 14.8489C16.7068 14.2797 17.0721 13.6525 17.3492 12.9835C17.7545 12.005 17.9632 10.9562 17.9632 9.8971C17.9632 8.83798 17.7545 7.78923 17.3492 6.81073C16.9439 5.83223 16.3499 4.94315 15.6009 4.19424C14.852 3.44533 13.963 2.85126 12.9845 2.44595C12.006 2.04064 10.9572 1.83203 9.89808 1.83203C8.83896 1.83203 7.79021 2.04064 6.81171 2.44595L7.19439 3.36983L6.81171 2.44595C5.83321 2.85126 4.94412 3.44533 4.19521 4.19424Z"
                        stroke="#68859B"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span className="flex-1  text-left whitespace-nowrap">
                      Search
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
          </>
        </div>
      </div>
    </>
  );
}

export default Dashboardnavbar;










