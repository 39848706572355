import React, { useState, useEffect } from "react";
import { Table } from "antd";
import {
  deleteRequestList,
  getNonJoinersDetails,
} from "../../../Services/authHeader";
import NonJoiner from "../../AdminDashboard/Reviews/addNonJoinersForm";
import { PageConfig } from "../../../Services/Context";

const NonJoiners = ({ referenceDoctype, showForm, setShowForm }) => {
  const [employees, setEmployees] = useState([]);
  // const [showForm, setShowForm] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const {setisDeleteRequestCancel} = PageConfig();

  const getDeletionListing = async () => {
    try {
      const doctype = "Deletion Request";
      const res = await deleteRequestList(doctype);

      if (res?.data?.message) {
        const fetchedData = res.data.message;

        const filteredData = fetchedData.filter(
          (item) =>
            item.reference_doctype === referenceDoctype &&
            item.request_type === "Report"
        );

        setEmployees(filteredData);
      } else {
        console.log("No data received from API");
      }
    } catch (error) {
      console.error("Error fetching deletion listing:", error);
    }
  };

  useEffect(() => {
    getDeletionListing();
  }, [referenceDoctype]);

  const columns = [
    { title: "ID", dataIndex: "reference_name", key: "reference_name" },
    {
      title: "Submitted By",
      dataIndex: "request_creator",
      key: "request_creator",
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
      render: (text) => (
        <span title={text}>
          {text.length > 50 ? `${text.substring(0, 50)}...` : text}
        </span>
      ),
    },
    { title: "Status", dataIndex: "status", key: "status" },
    {
      title: "Action",
      key: "view",
      render: (_, record) => (
        <button
          onClick={() => handleViewClick(record)}
          className="text-blue-600 hover:underline"
        >
          View
        </button>
      ),
    },
  ];

  const handleViewClick = async (record) => {
    setisDeleteRequestCancel(false)
    try {
      const res = await getNonJoinersDetails(record.reference_name);
      console.log(res.data.data, "non");
      if (res?.data) {
        setSelectedEmployee(res.data.data); // Assuming the response data contains the details you need
        setShowForm(true);
      } else {
        console.log("No details found for this record");
      }
    } catch (error) {
      console.error("Error fetching details:", error);
    }
  };

  const handleCloseForm = () => {
    setShowForm(false);
    setSelectedEmployee(null);
  };

  const isSuperAdmin = true

  return (
    <div>
      {showForm ? (
        <NonJoiner
          employee={selectedEmployee}
          onClose={handleCloseForm}
          // reset={handleReset}
          // onFormSubmit={handleFormSubmit}
          isSuperadmin={isSuperAdmin}
        />
      ) : (
        <Table
          dataSource={employees}
          columns={columns}
          pagination={{ pageSize: 10 }}
          rowKey="reference_name"
        />
      )}
    </div>
  );
};

export default NonJoiners;
