import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import AuthHeader, { getUser, getUserDetails } from "../Services/authHeader";
import { useNavigate } from "react-router-dom";
import User from "../Assets/Images/user-icon.png";
import Prizes from "../Assets/Images/prize.png";
import Image from "../Assets/Images/close.png"; // updated path for the image
import Adminusericon from "../Assets/Images/admin-user-icon.png";
import "../superadmin.css";
import { getUsers } from "../Services/authHeader";

function SuperAdminNav() {
  const [isOpen, setIsOpen] = useState(false);
  const [showMenu, setShowMenu] = useState(false); // State to toggle menu visibility
  const [getUser, setGetUser] = useState();
  const [profileData, setProfileData] = useState();

  const navigate = useNavigate();
  const [userList, setUserList] = useState([]);
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  const addUser = Yup.object().shape({
    email: Yup.string().required("Please enter your email"),
    first_name: Yup.string().required("Enter your first name"),
    company: Yup.string().required("Enter your company name"),
    phone: Yup.string().required("Please enter your mobile number"),
    password: Yup.string().required("Please enter password"),
  });

  const fetchUserData = async (userId) => {
    try {
      const data = await getUser(userId);
  
      setUserDetails(data.data.data);
    } catch (error) {
      console.error("Error fetching user details:", error);
    } finally {
      setLoading(false);
    }
  };

  // console.log(userDetails, "lllllll");

  useEffect(() => {
    const id = JSON.parse(sessionStorage.getItem("usr"));
    const userID = id?.email;

    if (userID) {
      fetchUserData(userID);
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
      first_name: "",
      company: "",
      phone: "",
      password: "",
    },
    validationSchema: addUser,
    onSubmit: (values) => {
      addReviewUser(values);
      navigate("/data");
    },
  });

  const handleToggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleClose = () => {
    setIsOpen(false);
    const darkModeElement = document.getElementById("dark-mode-on");
    if (darkModeElement) {
      darkModeElement.classList.remove("dark-mode-on");
    }
  };

  async function addReviewUser(values) {
    console.log(values);
    try {
      await AuthHeader.addUser(
        values.email,
        values.first_name,
        values.phone,
        values.company,
        values.password
      );
    } catch (error) {
      console.log("error", error);
    }
  }

  // to get the user name and role (image not available rn)
  useEffect(() => {
    const storedData = sessionStorage.getItem("usr");
    if (storedData) {
      setGetUser(JSON.parse(storedData));
    }
  }, []);

  const fetchProfileData = async () => {
    try {
      const data = await AuthHeader.ProfileData(getUser.email);
      console.log(data);
      setProfileData(data);
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  useEffect(() => {
    if (getUser) {
      fetchProfileData();
    }
  }, [getUser]);

  return (
    <>
      <nav className="admin-top-nav">
        <div className="page-heading">
          {window.location.pathname.split("/").pop() === "superAdmin"
            ? "Super Admin"
            : window.location.pathname.split("/").pop() === "CompanyList" ? "Company List" : window.location.pathname.split("/").pop() ==="DeleteRequest" ? "Delete Request" : window.location.pathname.split("/").pop()==="ReportedReviewSave"? "Reported Review Save" : window.location.pathname.split("/").pop() ==="ReviewsList" ? "Reviews List" : window.location.pathname.split("/").pop()}
        </div>
        {/* Toggle button for mobile */}
        <button
          onClick={handleToggleMenu}
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-900 dark:focus:ring-gray-900 admin-toggle"
          aria-expanded={showMenu}
        >
          <span className="sr-only">Toggle Menu</span>
          <svg
            className={`w-5 h-5 ${showMenu ? "hidden" : ""}`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
          <svg
            className={`w-5 h-5 ${showMenu ? "" : "hidden"}`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        {/* Menu items */}
        <div
          className={`md:flex md:items-center toggle-nav md:ml-auto transition-transform ${
            showMenu ? "block" : "hidden"
          }`}
        >
          {/* <div className="nav-btns points-count">
            <img src={Prizes} alt="" />
            1000 Points
          </div> */}
          {/* <div className="nav-btns">
            <img className="rounded-full user-img" src={User} alt="user" />
            <div className="add-user">
              <button
                onClick={() => {
                  setIsOpen(true);
                  const darkModeElement = document.getElementById("dark-mode-on");
                  if (darkModeElement) {
                    darkModeElement.classList.add("dark-mode-on");
                  }
                }}
              >
                Add User +
              </button>
            </div>
          </div> */}
          <div className="dropdown">
            <button
              className="flex items-center super-admin-logout space-x-8 pr-4 h-13 border border-gray-300 rounded-full py-1"
              onClick={() => setShowMenu(!showMenu)} // Toggle menu visibility on click
            >
              <img
                className="Adminusericon rounded-full"
                src={userDetails?.user_image || Adminusericon}
                alt=""
              />
              <h3 className="block admin-name-txt">
                {profileData?.full_name || "Administrator"}
                <span className="block admin-txt">
                  {profileData?.role_profile_name}
                </span>
              </h3>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 14L8 10H16L12 14Z"
                  fill="#647086"
                  stroke="#647086"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            {/* dropdown menu */}
            <div
              className={`z-10 w-40 h-auto mt-4 bg-white absolute dropdown-logout rounded-full divide-y divide-gray-100 shadow ${
                showMenu ? "block" : "hidden"
              }`}
            >
              <Link
                to="/Logout"
                className="block py-1 px-2 text-left logout-link"
              >
                Logout
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default SuperAdminNav;
