import React, { useEffect, useState } from "react";
import { Table, Layout, Spin, Input, Form, Button } from "antd";
import Sider from "antd/es/layout/Sider";
import { Content } from "antd/es/layout/layout";
import { userSubscriptionData } from "../../../Services/authHeader";
import SuperAdminSideNavbar from "../../../Widgets/SuperAdminDashboardNavbar";
import SuperAdminNav from "../../../Widgets/SuperadmincommonNav";
import { toast } from "react-toastify";
import { getUpdateSubscriptionPlan } from "../../../Services/superadminService";
import { PageConfig } from "../../../Services/Context";

const SuperAdminBilling = () => {
  const [subscriptionData, setSubscriptionData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isAllEditable, setIsAllEditable] = useState(false);
  const [pointsEarned, setPointsEarned] = useState(10);
  const [pointsDeducted, setPointsDeducted] = useState(5);
  const [form] = Form.useForm();
  const { setDropdownVisible} = PageConfig();

  useEffect(() => {
    fetchSubscriptionData();
    setDropdownVisible(false)
  }, []);

  const fetchSubscriptionData = async () => {
    try {
      setLoading(true);
      const res = await userSubscriptionData();
      if (res.status === 200) {
        const tableData = res.data.message.subscription_table;
        setSubscriptionData(tableData);
        const formValues = {};
        tableData.forEach((item) => {
          formValues[item.subscription_plan_type] = {
            subscription_plan_type: item.subscription_plan_type,
            amount: item.amount,
            points: item.points,
          };
        });
        form.setFieldsValue(formValues);

        setPointsEarned(res.data.message.points_addition_on_review_creation);
        setPointsDeducted(res.data.message.points_deduction_on_search_details);
      } else {
        toast.error("Error Getting Subscription Data");
      }
    } catch (error) {
      toast.error("Error Getting Subscription Data");
    } finally {
      setLoading(false);
    }
  };

  const saveAll = async () => {
    try {
      const rows = await form.validateFields();
      const newData = subscriptionData.map((item) => {
        const row = rows[item.subscription_plan_type];
        return {
          ...item,
          subscription_plan_type: row.subscription_plan_type,
          amount: row.amount,
          points: row.points,
        };
      });
      setSubscriptionData(newData);

      const payload = {
        data: {
          subscription_table: newData.map((item) => ({
            subscription_plan_type: item.subscription_plan_type,
            amount: item.amount,
            points: item.points,
          })),
          points_earned_by_add_review: pointsEarned,
          points_deduction_on_search_details: pointsDeducted,
        },
      };

      await getUpdateSubscriptionPlan(payload);
      toast.success("Subscription Plan Updated Successfully");
      setIsAllEditable(false);
    } catch (errInfo) {
      toast.error("Error saving data");
      console.log("Validate Failed:", errInfo);
    }
  };

  const cancel = () => {
    setIsAllEditable(false);
  };

  const columns = [
    {
      title: "Package name",
      dataIndex: "subscription_plan_type",
      key: "subscription_plan_type",
      editable: true,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      editable: true,
    },
    {
      title: "Points/duration",
      dataIndex: "points",
      key: "points",
      editable: true,
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType:
          col.dataIndex === "amount" || col.dataIndex === "points"
            ? "number"
            : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isAllEditable,
      }),
    };
  });

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={[record.subscription_plan_type, dataIndex]}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            <Input type={inputType} />
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  document.body.classList.add("billing-page");
  document.body.classList.remove(
    "dashboard-page",
    "profile-page",
    "issue-page",
    "search-page",
    "users-page",
    "reports-page",
    "companyreportsave-page",
    "review-page",
    "approve-page",
    "deletion-page"
  );

  return (
    <>
      <div id="dark-mode-on"></div>
      <Layout className="h-full">
        <Sider width={256} className="site-layout-background">
          <SuperAdminSideNavbar />
        </Sider>
        <Layout className="panel-right">
          <SuperAdminNav />
          <Content className="dashboard-layout">
            <div className="section-head">
              <h2>Subscription Packages</h2>
              <div className="right-head">
                {isAllEditable ? (
                  <>
                    <Button onClick={cancel} className="btn btn-secondary">
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      onClick={saveAll}
                      className="btn btn-primary dark-btn"
                      style={{ marginRight: 8 }}
                    >
                      Save
                    </Button>
                  </>
                ) : (
                  <Button
                    type="primary"
                    onClick={() => setIsAllEditable(true)}
                    className="btn btn-primary dark-btn"
                  >
                    Edit
                  </Button>
                )}
              </div>
            </div>
            <div className="w-auto overflow-auto">
              {loading ? (
                <Spin size="large" />
              ) : (
                <Form form={form} component={false}>
                  <Table
                    components={{
                      body: {
                        cell: EditableCell,
                      },
                    }}
                    dataSource={subscriptionData}
                    columns={mergedColumns}
                    pagination={false}
                    rowKey="subscription_plan_type"
                  />
                  <section className="billing-points">
                    <div>
                      <p>Points earned by Add Review</p>
                      <input
                        type="text"
                        value={pointsEarned}
                        disabled={!isAllEditable}
                        onChange={(e) => setPointsEarned(e.target.value)}
                      />
                    </div>
                    <div>
                      <p>Points deducted by Search</p>
                      <input
                        type="text"
                        value={pointsDeducted}
                        disabled={!isAllEditable}
                        onChange={(e) => setPointsDeducted(e.target.value)}
                      />
                    </div>
                  </section>
                </Form>
              )}
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default SuperAdminBilling;
