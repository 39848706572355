import { useState } from "react";
import Adminusericon from "../Assets/Images/admin-user-icon.png";

const SuperAdminNav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const handleToggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  const handleToggleLogoutMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setIsOpen(false);
    const darkModeElement = document.getElementById("dark-mode-on");
    if (darkModeElement) {
      darkModeElement.classList.remove("dark-mode-on");
    }
  };
  // console.log("window.location.pathname.split("/")",window.location.pathname.split("/"))
  return (
    <nav className="admin-top-nav">
      <div className="page-heading">
        {window.location.pathname.split("/")[1] === "superAdmin"
          ? window.location.pathname.split("/")[2]==="Billing" ? "Billing" : "Approve Request" 
          : window.location.pathname.split("/")[1]}
      </div>
      {/* Toggle button for mobile */}
      <button
        onClick={handleToggleMobileMenu}
        className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-900 dark:focus:ring-gray-900 admin-toggle"
        aria-expanded={showMobileMenu}
      >
        <span className="sr-only">Toggle Menu</span>
        <svg
          className={`w-5 h-5 ${showMobileMenu ? "hidden" : ""}`}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16m-7 6h7"
          />
        </svg>
        <svg
          className={`w-5 h-5 ${showMobileMenu ? "" : "hidden"}`}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      {/* Navbar links */}
      <div
        className={`md:flex md:items-center toggle-nav md:ml-auto transition-transform ${
          showMobileMenu ? "block" : "hidden"
        }`}
      >
        <div className="dropdown">
          {/* Admin user dropdown */}
          <button
            className="flex items-center super-admin-logout space-x-8 pr-4 h-13 border border-gray-300 rounded-full mr-4 py-1"
            onClick={handleToggleLogoutMenu} // Toggle dropdown
          >
            <img
              className="Adminusericon rounded-full"
              src={Adminusericon}
              alt=""
            />
            <h3 className="block admin-name-txt">
              Stephen Austin <span className="block admin-txt">Admin</span>
            </h3>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="text-gray-600 dark:text-white"
            >
              <path
                d="M12 14L8 10H16L12 14Z"
                fill="#647086"
                stroke="#647086"
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          {/* Dropdown menu */}
          <div
            id="dropdown"
            className={`z-10 w-40 h-auto mt-4 bg-white absolute dropdown-logout rounded-full divide-y divide-gray-100 shadow ${
              isOpen ? "block" : "hidden"
            }`}
          >
            <a href="/logout" className="block py-1 px-2 text-left logout-link">
              Logout
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default SuperAdminNav;
