import React, { useState, useRef } from "react";
import { DeleteFile, DeleteFileUser, uploadFile } from "../Services/authHeader";
import { toast } from "react-toastify";

// Confirmation Modal Component
function ConfirmationModal({ onConfirm, onCancel }) {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h3 className="text-lg font-semibold mb-4">Confirm Deletion</h3>
        <p className="mb-6">Are you sure you want to delete this file?</p>
        <div className="flex justify-end">
          <button
            onClick={onCancel}
            className="bg-gray-300 text-gray-800 px-4 py-2 rounded mr-2"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="bg-red-500 text-white px-4 py-2 rounded"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
}

function UploadButton({
  fileNames,
  setFileNames,
  fieldName,
  flag,
  setFlag,
  fileTypes,
  label,
  doctype,
  docname,
  disabled,
  GetFilePath,
  setProfileData,
  fetchProfileData,
  fetchCompanyDetails

}) {
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [showModal, setShowModal] = useState(false); // For controlling the modal
  const fileInputRef = useRef(null);


  const DeleteFileFunction = async (fieldName) => {
    const company = sessionStorage.getItem("company")
    const body = {
      data: {
        [fieldName]: ""
      }
    };
    try {

      const deleteRes = await DeleteFile(company, body)
      if (deleteRes.status === 200) {
        toast.success("Delete Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        
      }
    } catch (err) {
      console.log("Error got in DeleteFileFunction", err)
    }
  }



  const DeleteFileFunctionForUser = async (fieldName) => {
    const user = JSON.parse(sessionStorage.getItem("usr"))
    const body = {
      data: {
        [fieldName]: ""
      }
    };
    try {
      const deleteRes = await DeleteFileUser(user.email, body)
      if (deleteRes.status === 200) {
        fetchProfileData()
        fetchCompanyDetails()
        toast.success("Delete Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      }
    } catch (err) {
      console.log("Error got in DeleteFileFunction", err)
    }
  }

  const handleChange = (file) => {
    if (!loading && !disabled) {
      setLoading(true);
      if (file) {
        const fileExtension = file.name.split('.').pop().toLowerCase();
        const allowedExtensions = fileTypes.map((type) => type.replace(".", ""));
     
        if (!allowedExtensions.includes(fileExtension)) {
          toast.error("Invalid file type. Allowed types are: .png, .jpg, .jpeg, .pdf, .doc, .docx");
          setLoading(false);
          return;
        }
        setFileName(file.name);
        const reader = new FileReader();
        reader.onload = (readerEvt) => {
          handleReaderLoaded(readerEvt, file.name);
        };
        reader.readAsBinaryString(file);
      }
    }
  };

  const handleReaderLoaded = (readerEvt, fileName) => {
    let binaryString = readerEvt.target.result;
    const fileData = btoa(binaryString);
    handleUploadFile({ fileName, fileData });
  };

  const handleUploadFile = async ({ fileName, fileData }) => {
    const res = await uploadFile({
      fileName,
      fileData,
      doctype,
      docname,
      fieldName,
    });
    if (res.status === 200) {
      const data = { ...fileNames };
      data[fieldName] = res.data.message.file_url; // Set the specific field
      setFileNames(data);
      setFlag(!flag);
      fetchProfileData()
    }else{
      toast.error("Please try again File is not uploaded", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    }
    setLoading(false);
  };

  const handleDeleteFile = () => {
    setShowModal(true); // Show the modal when delete is clicked
  };

  const confirmDelete = () => {
    const data = { ...fileNames };
    delete data[fieldName]; // Remove the specific field
    setFileNames(data);
    setFlag(!flag);
    setShowModal(false); // Close the modal

    if (fieldName === "add_document") {
      DeleteFileFunctionForUser(fieldName)
      setProfileData((prev) => ({
        ...prev,
        add_document: "", 
      }));

    } else {
      DeleteFileFunction(fieldName)
    }


    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Reset file input
    }
    setFileName(""); // Clear the file name in state

  };

  const cancelDelete = () => {
    setShowModal(false); // Close the modal without deleting
  };

  const triggerFileInputClick = (event) => {
    event.preventDefault(); // Prevents default action
    if (!disabled && !loading) {
      fileInputRef.current.click();
    }
  };

  const isFileUploaded = Boolean(fileNames[fieldName] || GetFilePath);

  return (
    <div className="pb-4 input-width">
      <div className="relative flex field-container">
        <input
          type="text"
          id={`file-url-${fieldName}`}
          name={`file-url-${fieldName}`}
          placeholder={label}
          value={
            fileNames[fieldName]?.replace(/^\/files\//, "") || "" || GetFilePath?.replace(/^\/files\//, "")
          }
          className="w-full bg-white bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
          readOnly
          disabled={disabled}
        />
        <label htmlFor={`file-url-${fieldName}`} className="placeholders">
          {label}
        </label>
        <input
          type="file"
          id={`file-${fieldName}`}
          name={`file-${fieldName}`}
          accept={fileTypes.join(",")}
          onChange={(e) => handleChange(e.target.files[0])}
          className="hidden"
          ref={fileInputRef}
        />
        {!isFileUploaded ? (
          <label
            htmlFor={`file-${fieldName}`}
            className={`upload-btn flex items-center justify-center ml-2 ${loading || disabled ? "cursor-not-allowed opacity-50" : ""
              }`}
            onClick={triggerFileInputClick}
          >
            {loading ? "Uploading..." : "Upload"}
          </label>
        ) : (
          <button
            className={`delete-btn flex items-center justify-center bg-red-500 text-white px-3 py-1 ${loading || disabled ? "cursor-not-allowed opacity-50" : ""
              }`}
            onClick={handleDeleteFile}
            disabled={disabled || loading}
          >
            Delete
          </button>
        )}
      </div>

      {/* Show confirmation modal if needed */}
      {showModal && (
        <ConfirmationModal onConfirm={confirmDelete} onCancel={cancelDelete} />
      )}
    </div>
  );
}

export default UploadButton;
