import { Spin } from 'antd';
import React from 'react';


const Model = ({spinLoading}) => {

    return (
        <div className="spin-container">
            <Spin spinning={spinLoading} tip="Loading..." size="large">
            </Spin>
        </div>
    );
}

export default Model;
