import React, { useState, useEffect, useRef } from "react";
import Dashboardnavbar from "../../Widgets/Dashboardnavbar";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import AuthHeader, {
  getCompanyRegAndGstFile,
  ProfileData,
  uploadFile,
  uploadProfile,
} from "../../Services/authHeader";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import { AiFillEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Col } from "react-bootstrap";
import CommonNavbar from "../../Widgets/CommonNavbar";
import Adminusericon from "../../Assets/Images/admin-user-icon.png";
import { Layout, notification, Spin } from "antd";
import { ToastContainer } from "react-toastify";
import Sider from "antd/es/layout/Sider";
import { Content } from "antd/es/layout/layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import UploadButton from "../uploadButton";
import { getCompanyDetails } from "../../Services/superadminService";
import { toast } from "react-toastify";
import { CompanyWorkFlowConfig } from "../../Services/companyContext";

const Profile = () => {
  const [getUser, setGetUser] = useState();
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companyData, setCompanyData] = useState([]);
  const [profileData, setProfileData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState({
    current: false,
    new: false,
    confirm: false,
  });
  const [imageURL, setImageURL] = useState("");
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(false);
  const [fileNames, setFileNames] = useState({});
  const [reconfig, setreconfig] = useState(false);
  const [fileData, setfileData] = useState({});
  const [loadingProfile, setloadingProfile] = useState(false);
  const [loadingSave, setloadingSave] = useState(false);

  // console.log("###########selectedCompany",selectedCompany)
  // console.log("########### ProfileData",profileData)
  const Url = process.env.REACT_APP_API_URL;


  const togglePasswordVisibility = (field) => {
    setShowPassword((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const handleFileUpload = async (event, fieldName) => {
    const file = event.target.files[0];
    if (!file) return;
    // Allowed file types
    const allowedFileTypes = ["image/jpeg", "image/png", "image/jpg"];
    const fileType = file.type;


    // Check if file is valid
    if (!allowedFileTypes.includes(fileType)) {
      toast.error("Only .jpg, .jpeg, and .png files are allowed.");
      return;
    }

    setLoading(true);
    const reader = new FileReader();
    reader.onload = (readerEvt) => {
      let binaryString = readerEvt.target.result;
      const fileData = btoa(binaryString);
      uploadfile({ fileName: file.name, fileData, fieldName });
    };
    reader.readAsBinaryString(file);
  };

  const uploadfile = async ({ fileName, fileData, fieldName }) => {
    const res = await uploadProfile({
      fileName,
      fileData,
      doctype: "User",
      docname: getUser.email,
    });
    if (res.status === 200) {
      setProfileData({
        ...profileData,
        [fieldName]: res.data.message.file_url,
      });
      setFlag(!flag);
      // setImageURL(
      //   `https://secondjudgedeverp.nestorbird.com${res.data.message.file_url}`
      // );
      setImageURL(`${res.data.message.file_url}`);
    }else{
      toast.error("Please try again File is not uploaded", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    }

    setLoading(false);
  };

  // console.log(imageURL, "Check");

  useEffect(() => {
    const storedData = sessionStorage.getItem("usr");
    if (storedData) {
      setGetUser(JSON.parse(storedData));
    }
  }, []);

  const fetchProfileData = async () => {
    setloadingProfile(true)
    try {
      const data = await ProfileData(getUser?.email);
      // console.log("profileData_One",data);
      setProfileData(data);
    } catch (error) {
      console.error("Error fetching profile data:", error);
    } finally {
      setloadingProfile(false);
    }
  };

  useEffect(() => {
    if (getUser) {
      fetchProfileData();

      setImageURL(profileData ? `${profileData.user_image}` : "");
    }
    // fetchCompanyDetails(profileData?.company);
  }, [getUser]);

  useEffect(() => {
    const storedData = sessionStorage.getItem("usr");
    if (storedData) {
      const userData = JSON.parse(storedData);
      setGetUser(userData);
      fetchProfileData(userData.email)
        .then((profileData) => {
          // console.log("profileData_Two",profileData);
          setProfileData(profileData);
          setImageURL(
            profileData?.user_image ? `${profileData.user_image}` : ""
          );
          fetchCompanyDetails(profileData?.company);
        })
        .catch((error) => {
          console.error("Error fetching profile data:", error);
        });
    }
  }, [reconfig]);

  async function fetchCompanyDetails(companyName) {
    try {
      const companyDetails = await getCompanyDetails(companyName);
      setSelectedCompany(companyDetails);
      //sessionStorage.setItem("workflow_state",companyDetails.workflow_state==="Approved" ? true : false)
      // console.log(companyDetails, "pppppp");
      // setShowTable(false); // Hide table and show details
    } catch (error) {
      console.log(error);
    }
  }

  const handleEditClick = () => {
    setIsEditing(true);
    // console.log("dhusudh", profileData);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setreconfig((prev) => !prev);
  };

  // console.log("imageURL",imageURL,"profileData?.user_image",profileData);

  // useEffect(() => {
  //   console.log("profile pic image url ", imageURL, profileData?.user_image);
  // }, [imageURL, profileData]);

  const handleSaveClick = async () => {
    // Call your save API here
   setloadingSave(true)
    try {
      const res = await AuthHeader.updateUserDetails(
        profileData,
        getUser.email
      );
      if (res.status === 200) {
        const updateData = { ...selectedCompany, buisness_email: profileData.email, buisness_phone: profileData.mobile_no, region: profileData.region }
        const resCompanyDetailUpdate = await AuthHeader.updateCompanyDetails(updateData);
        if (resCompanyDetailUpdate.status === 200) {
          setIsEditing(false);
          setreconfig((prev) => !prev);
          toast.success("Update Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });

        } else {
          notification.error({
            message: "Failed to Update",
          });
        }
      } else {
        notification.error({
          message: "Failed to Update",
        });
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setloadingSave(false)
    }
  };

  const formik = useFormik({
    initialValues: {
      email: profileData?.email || "",
      first_name: profileData?.first_name || "",
      phone: profileData?.phone || "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required("Please enter your email"),
      first_name: Yup.string().required("Enter your first name"),
      phone: Yup.string().required("Please enter your mobile number"),
    }),
    onSubmit: (values) => {
      handleSaveClick();
    },
  });

  const handleInputChange = (e) => {
    setProfileData({ ...profileData, [e.target.name]: e.target.value });
    console.log(e.target.name, e.target.value, "changed");
  };

  const handleInputChangeCompanyDetails = (e) => {
    setSelectedCompany({ ...selectedCompany, [e.target.name]: e.target.value });
  };

  const handlePasswordChangeClick = () => {
    setIsChangingPassword(true);
  };

  const handlePasswordChangeCancelClick = () => {
    setIsChangingPassword(false);
    setCurrentPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setPasswordError("");
  };

  // const handleUpdatePasswordClick = async () => {
  //   if (newPassword === confirmPassword) {
  //     try {
  //       const res = await AuthHeader.updateUserPassword({
  //         new_password: newPassword,
  //         confirm_password: confirmPassword,
  //         email: getUser.email,
  //       });
  //       setIsChangingPassword(false);
  //       setCurrentPassword("");
  //       setNewPassword("");
  //       setConfirmPassword("");
  //       setPasswordError("");
  //       toast.success("Password has been successfully changed.", {
  //         position: toast.POSITION.TOP_RIGHT,
  //         autoClose: 2000,
  //       });
  //       console.log(res, "Password");
  //     } catch (error) {
  //       if (error.response.data.message === "New Password is required") {
  //         toast.info("New Password is required", {
  //           position: toast.POSITION.TOP_RIGHT,
  //           autoClose: 2000,
  //         });
  //       }
  //       console.log("error", error);
  //     }
  //   } else {
  //     setPasswordError("Passwords do not match");
  //   }
  // };
  // -----------------------------------------------------------
  // List of common passwords
  const commonPasswords = [
    "password", "123456", "123456789", "qwerty", "abc123", "password1", "111111", "admin",
    "welcome", "letmein", "monkey", "dragon", "football", "iloveyou", "sunshine", 
    "123123", "admin123", "login", "princess", "000000", "trustno1", "123qwe", 
    "654321", "superman", "starwars", "michael", "shadow", "master", "hello", 
    "freedom", "whatever", "password123", "batman", "1q2w3e4r", "qwertyuiop", 
    "123abc", "qazwsx", "asdfghjkl", "password!", "zaq12wsx", "charlie", "donald", 
    "liverpool", "1234abcd", "1qaz2wsx", "12345678", "passw0rd", "qwerty123", 
    "football1", "letmein123", "baseball", "jordan23", "welcome123", "flower","admin@123","Admin@123"
  ];
  

  // Function to validate the password
  const validatePassword = (password) => {
    const lengthCheck = password.length >= 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const isCommonPassword = commonPasswords.includes(password);

    if (!lengthCheck) return "Password must be at least 8 characters long.";
    if (!hasUpperCase) return "Password must include at least one uppercase letter.";
    if (!hasLowerCase) return "Password must include at least one lowercase letter.";
    if (!hasNumber) return "Password must include at least one number.";
    if (!hasSpecialChar) return "Password must include at least one special character.";
    if (isCommonPassword) return "Password is too common. Please choose a stronger password.";

    return ""; // Empty string means password is valid
  };

  // Password Update Handler
  const handleUpdatePasswordClick = async () => {
    // Check if passwords match
    if (newPassword !== confirmPassword) {
      setPasswordError("Passwords do not match.");
      return;
    }

    // Validate password
    const validationError = validatePassword(newPassword);
    if (validationError) {
      setPasswordError(validationError); // Set error message if validation fails
      toast.error(validationError, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }

    try {
      const res = await AuthHeader.updateUserPassword({
        new_password: newPassword,
        confirm_password: confirmPassword,
        email: getUser.email,
      });

      // Reset password fields and show success message
      setIsChangingPassword(false);
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
      setPasswordError("");
      toast.success("Password has been successfully changed.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      console.log(res, "Password");
    } catch (error) {
      if (error.response?.data?.message === "New Password is required") {
        toast.info("New Password is required", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      }
      console.log("error", error);
    }
  };

  // -----------------------------------------------------------

  const triggerFileInputClick = () => {
    fileInputRef.current.click();
  };

  document.body.classList.remove(
    "billing-page",
    "dashboard-page",
    "billing-page",
    "search-page",
    "users-page",
    "review-page"
  );
  document.body.classList.add("profile-page");

  const handleSetFileNames = (updatedFileNames) => {
    // console.log("Updated File Names:", updatedFileNames);

    setFileNames(updatedFileNames);

    setProfileData((prevData) => ({
      ...prevData,
      ...updatedFileNames,
    }));

    // console.log("Updated Profile Data:", profileData);
  };

  useEffect(() => {
    if (profileData) {
      getCompanyRegAndGstFile(profileData?.company).then((res) => {
        setfileData(res.data.data);
      });
      fetchCompanyDetails(profileData?.company);
    }
  }, [profileData]);

  // const workflow_state = JSON.parse(sessionStorage.getItem("workflow_state"));

  return (
    <Layout className="h-screen">
      <ToastContainer />
      <Sider width={256} className="site-layout-background">
        <Dashboardnavbar />
      </Sider>
      <Layout className="panel-right">
        <CommonNavbar reconfig={reconfig} />
        <Content>
          <div class="w-auto profile-section">
            <div class="caption-top">
              <h2 class="profile-txt">
                {isChangingPassword ? "Change Password" : "Profile Details"}
              </h2>
              {!isEditing && !isChangingPassword && (
                <div class="md:ml-auto flex flex-wrap items-center text-base justify-center pr-0">
                  <button
                    class="bg-gray-800 text-white font-base mr-5 px-4 rounded changepassword-btn"
                    onClick={handlePasswordChangeClick}
                  >
                    Change Password
                  </button>
                  <button
                    class="bg-gray-800 text-white font-base px-4 rounded edit-btn"
                    onClick={handleEditClick}
                  // disabled={!workflow_state}
                  >
                    Edit
                  </button>
                </div>
              )}
              {isEditing && (
                <div class="md:ml-auto flex flex-wrap items-center text-base justify-center pr-0">
                  <Spin spinning={loadingSave}>
                    <button
                      class="bg-gray-800 text-white font-base mr-5 py-2 px-4 rounded"
                      onClick={handleSaveClick}
                    >
                      Save
                    </button>
                  </Spin>
                  <button
                    class="bg-gray-800 text-white font-base py-2 px-4 rounded"
                    onClick={handleCancelClick}
                  >
                    Cancel
                  </button>
                </div>
              )}
              {isChangingPassword && (
                <div class="change-pwd-btns">
                  <button onClick={handlePasswordChangeCancelClick}>
                    Cancel
                  </button>
                  <button
                    onClick={handleUpdatePasswordClick}
                    className="dark-btn"
                  >
                    Update Password
                  </button>
                </div>
              )}
            </div>
            <table class="w-full">
              {isChangingPassword ? (
                <section className="flex-profile change-password">
                  {/* <div className="field-container w-5/12 relative">
                    <input
                      type={showPassword.current ? "text" : "password"}
                      id="current_password"
                      name="current_password"
                      placeholder="Current Password"
                      value={currentPassword}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                      className="pr-10"
                    />
                    <label htmlFor="current_password" className="placeholders">
                      Current Password
                    </label>
                    <FontAwesomeIcon
                      icon={showPassword.current ? faEye : faEyeSlash}
                      onClick={() => togglePasswordVisibility("current")}
                      className="absolute right-3 top-3 cursor-pointer text-gray-500"
                    />
                  </div> */}

                  <div className="field-container w-5/12 relative">
                    <input
                      type={showPassword.new ? "text" : "password"}
                      id="new_password"
                      name="new_password"
                      placeholder="New Password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      className="pr-10"
                    />
                    <label htmlFor="new_password" className="placeholders">
                      New Password
                    </label>
                    <FontAwesomeIcon
                      icon={showPassword.new ? faEye : faEyeSlash}
                      onClick={() => togglePasswordVisibility("new")}
                      className="absolute right-3 top-3 cursor-pointer text-gray-500"
                    />
                  </div>
                  <div className="field-container w-5/12 relative">
                    <input
                      type={showPassword.confirm ? "text" : "password"}
                      id="confirm_password"
                      name="confirm_password"
                      placeholder="Confirm Password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      className="pr-10"
                    />
                    <label htmlFor="confirm_password" className="placeholders">
                      Confirm Password
                    </label>
                    <FontAwesomeIcon
                      icon={showPassword.confirm ? faEye : faEyeSlash}
                      onClick={() => togglePasswordVisibility("confirm")}
                      className="absolute right-3 top-3 cursor-pointer text-gray-500"
                    />
                  </div>
                  {passwordError && (
                    <div className="p-2 w-full md:w-1/2">
                      <p className="text-red-500">{passwordError}</p>
                    </div>
                  )}
                </section>
              ) : (
                <section class="text-gray-600 body-font relative">
                  <div class="container m-0 pl-0 pr-0">
                    <div className="flex mb-5">
                      {/* {console.log(
                        "image",
                        imageURL || profileData?.user_image,
                        imageURL,
                        profileData?.user_image
                      )} */}

                      <Spin spinning={loading || loadingProfile}>
                        <img
                          src={
                            profileData?.user_image
                              ? profileData?.user_image.startsWith("http")
                                ? profileData?.user_image
                                : `${Url}${imageURL || profileData?.user_image
                                }`
                              : `https://ui-avatars.com/api/?name=${profileData?.first_name}&rounded=true&background=random`
                          }
                          alt="Profile Pic"
                          className={`w-36 h-36  ${isEditing ? "cursor-pointer" : "cursor-default"
                            }`}
                        />
                      </Spin>

                      {isEditing && !loading && (
                        <>
                          <button
                            className={`upload-img-btn mt-auto ml-2 ${!isEditing ? "cursor-not-allowed" : ""
                              }`}
                            onClick={triggerFileInputClick}
                            disabled={!isEditing}
                          >
                            Upload
                          </button>
                          <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: "none" }}
                            onChange={(e) => handleFileUpload(e, "user_image")}
                          />
                        </>
                      )}
                    </div>
                    <div class="lg:w-full md:w-full mx-auto">
                      <div class="flex flex-wrap -m-2 flex-profile">
                        <div class="p-2 w-1/2 pr-4 pb-4 input-width">
                          <div class="relative field-container">
                            <input
                              type="email"
                              id="email"
                              name="email"
                              placeholder="Business Email"
                              value={profileData?.email || ""}
                              onChange={handleInputChange}
                              disabled={true}
                            />
                            <label htmlFor="email" className="placeholders">
                              Business Email
                            </label>
                          </div>
                        </div>
                        <div class="p-2 w-1/2 pl-4 pb-4 input-width">
                          {/* <div class="relative field-container">
                            <input
                              type="number"
                              id="phone"
                              name="phone"
                              placeholder="Business Phone"
                              value={profileData?.mobile_no || "" || profileData?.phone}
                              onChange={(e) =>
                                setProfileData({
                                  ...profileData,
                                  mobile_no: e.target.value,
                                })
                              }
                              disabled={!isEditing}
                            />
                            <label
                              htmlFor="business_phone"
                              className="placeholders"
                            >
                              Business Phone
                            </label>
                          </div> */}

                          <div className="relative field-container">
                            <input
                              type="text"
                              id="phone"
                              name="phone"
                              placeholder="Business Phone"
                              value={profileData?.mobile_no || "" || profileData?.phone}
                              onChange={(e) => {
                                const phone = e.target.value;

                                // Check if the input is numeric and 10 digits long
                                if (/^\d{0,10}$/.test(phone)) {
                                  setProfileData({
                                    ...profileData,
                                    mobile_no: phone,
                                  });
                                }
                              }}
                              disabled={!isEditing}
                            />
                            <label htmlFor="business_phone" className="placeholders">
                              Business Phone
                            </label>
                          </div>

                        </div>
                        <div class="p-2 w-1/2 pr-4 pb-4 input-width">
                          <div class="relative field-container">
                            <input
                              type="text"
                              id="first_name"
                              name="first_name"
                              placeholder="First Name"
                              value={profileData?.first_name || ""}
                              onChange={handleInputChange}
                              disabled={!isEditing}
                            />
                            <label
                              htmlFor="first_name"
                              className="placeholders"
                            >
                              First Name
                            </label>
                          </div>
                        </div>
                        <div class="p-2 w-1/2 pl-4 pb-4 input-width">
                          <div class="relative field-container">
                            <input
                              type="text"
                              id="last_name"
                              name="last_name"
                              placeholder="Last Name"
                              value={profileData?.last_name || ""}
                              onChange={handleInputChange}
                              disabled={!isEditing}
                            />
                            <label htmlFor="last_name" className="placeholders">
                              Last Name
                            </label>
                          </div>
                        </div>
                        <div class="p-2 w-1/2 pr-4 pb-4 input-width">
                          <div class="relative field-container">
                            <input
                              type="text"
                              id="designation"
                              name="designation"
                              placeholder="Designation"
                              value={profileData?.designation || ""}
                              onChange={handleInputChange}
                              disabled={!isEditing}
                            />
                            <label
                              htmlFor="designation"
                              className="placeholders"
                            >
                              Designation
                            </label>
                          </div>
                        </div>
                        <div className="p-2 w-1/2 pl-4 pb-4 input-width">
                          <UploadButton
                            fileNames={fileNames}
                            setFileNames={handleSetFileNames}
                            fieldName="add_document"
                            flag={flag}
                            setFlag={setFlag}
                            fileTypes={[
                              ".png",
                              ".jpg",
                              ".jpeg",
                              ".pdf",
                              ".doc",
                              ".docx",
                            ]}
                            label="Add Document (e.g. Resume, Experience Letter)"
                            doctype="User"
                            docname={profileData?.email}
                            disabled={!isEditing}
                            GetFilePath={profileData?.add_document}
                            setProfileData={setProfileData}
                            fetchProfileData={fetchProfileData}


                          />
                        </div>

                        <caption class="caption-top w-full text-lg font-semibold text-left text-gray-900 bg-white dark:text-white dark:bg-gray-800">
                          <div className="container1 profile-container mx-auto flex flex-wrap p-5 pr-2 flex-col md:flex-row items-center">
                            <span class="ml-1 text-xl profile-txt">
                              Company Details
                            </span>
                          </div>
                        </caption>
                        <div class="p-2 w-1/2 pr-4 pb-4 input-width">
                          <div class="relative field-container">
                            <input
                              type="text"
                              id="company"
                              name="company"
                              placeholder="Company"
                              value={selectedCompany?.company || ""}
                              onChange={handleInputChangeCompanyDetails}
                              disabled={true}
                            />
                            <label htmlFor="company" className="placeholders">
                              Company
                            </label>
                          </div>
                        </div>
                        <div class="p-2 w-1/2 pl-4 pb-4 input-width">
                          <div class="relative field-container">
                            <input
                              type="text"
                              id="region"
                              name="region"
                              placeholder="Region"
                              value={selectedCompany?.region || profileData?.region || ""}
                              onChange={handleInputChangeCompanyDetails}
                              disabled={
                                !isEditing
                                  ? true
                                  : profileData?.role_profile_name ===
                                    "Semi-admin"
                                    ? false
                                    : true
                              }
                            />
                            <label htmlFor="region" className="placeholders">
                              Region
                            </label>
                          </div>
                        </div>
                        <div className="p-2 w-1/2 pr-4 pb-4 input-width">
                          <UploadButton
                            fileNames={fileNames}
                            setFileNames={handleSetFileNames}
                            fieldName="registration_no"
                            flag={flag}
                            setFlag={setFlag}
                            fileTypes={[
                              ".png",
                              ".jpg",
                              ".jpeg",
                              ".pdf",
                              ".doc",
                              ".docx",
                            ]}
                            label="Add Document (e.g. ID proof, Registration No)"
                            doctype="Company Profile"
                            docname={profileData?.company}
                            disabled={
                              !isEditing
                                ? true
                                : profileData?.role_profile_name ===
                                  "Semi-admin"
                                  ? false
                                  : true
                            }
                            GetFilePath={fileData.registration_no}
                            fetchProfileData={fetchProfileData}
                            fetchCompanyDetails={fetchCompanyDetails}
                          />
                        </div>

                        <div className="p-2 w-1/2 pl-4 pb-4 input-width">
                          <UploadButton
                            fileNames={fileNames}
                            setFileNames={handleSetFileNames}
                            fieldName="gst_no"
                            flag={flag}
                            setFlag={setFlag}
                            fileTypes={[
                              ".png",
                              ".jpg",
                              ".jpeg",
                              ".pdf",
                              ".doc",
                              ".docx",
                            ]}
                            label="Add Document (e.g. ID proof, Registration No)"
                            doctype="Company Profile"
                            docname={profileData?.company}
                            disabled={
                              !isEditing
                                ? true
                                : profileData?.role_profile_name ===
                                  "Semi-admin"
                                  ? false
                                  : true
                            }
                            GetFilePath={fileData.gst_no}
                            fetchProfileData={fetchProfileData}
                            fetchCompanyDetails={fetchCompanyDetails}
                          />
                        </div>

                        <div class="p-2 w-1/2 pr-4 pb-4 input-width">
                          <div class="relative field-container">
                            <input
                              type="text"
                              id="industry"
                              name="industry"
                              placeholder="Industry"
                              value={selectedCompany?.industry || ""}
                              onChange={handleInputChangeCompanyDetails}
                              disabled={
                                !isEditing
                                  ? true
                                  : profileData?.role_profile_name ===
                                    "Semi-admin"
                                    ? false
                                    : true
                              }
                            />
                            <label htmlFor="industry" className="placeholders">
                              Industry
                            </label>
                          </div>
                        </div>
                        <div class="p-2 w-1/2 pl-4 pb-4 input-width">
                          <div class="relative field-container">
                            <input
                              type="text"
                              id="no_of_employees"
                              name="no_of_employees"
                              placeholder="No of Employees"
                              value={selectedCompany?.no_of_employees || ""}
                              onChange={handleInputChangeCompanyDetails}
                              disabled={
                                !isEditing
                                  ? true
                                  : profileData?.role_profile_name ===
                                    "Semi-admin"
                                    ? false
                                    : true
                              }
                            />
                            <label
                              htmlFor="no_of_employees"
                              className="placeholders"
                            >
                              No of Employees
                            </label>
                          </div>
                        </div>
                        <div class="p-2 w-full pb-4 input-width">
                          <div class="relative field-container">
                            <input
                              type="text"
                              id="details"
                              name="details"
                              placeholder="Details"
                              value={selectedCompany?.details || ""}
                              onChange={handleInputChangeCompanyDetails}
                              disabled={
                                !isEditing
                                  ? true
                                  : profileData?.role_profile_name ===
                                    "Semi-admin"
                                    ? false
                                    : true
                              }
                            />
                            <label htmlFor="details" className="placeholders">
                              Details
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )}
            </table>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Profile;
