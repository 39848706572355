import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Image from "../../../Assets/Images/close.png";
import { addUser, fetchRegions } from "../../../Services/authHeader";
import { Spin, Select } from "antd";

const AddCompany = ({ handleClose }) => {
    const [loadingUser, setLoadingUser] = useState(false);
    const [getState, setGetState] = useState([]);

    // Validation schema
    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required("Please enter your email")
            .email("Please enter a valid email address")
            .matches(
                /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                "Please enter a valid email address"
            ),
        full_name: Yup.string().required("Enter your full name"),
        designation: Yup.string().required("Enter your designation"),
        company: Yup.string().required("Enter your company name"),
        phone: Yup.string()
            .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
            .required("Please enter your mobile number"),
        region: Yup.string().required("Please select a region"),
    });

    // Fetch regions on mount
    useEffect(() => {
        document.getElementById("dark-mode-on").classList.add("dark-mode-on");

        const fetchStateData = async () => {
            try {
                const regions = await fetchRegions();
                setGetState(regions);
            } catch (error) {
                const errorMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                toast.error(`Failed to fetch regions: ${errorMessage}`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        };

        fetchStateData();
    }, []);

    // Initialize Formik
    const formik = useFormik({
        initialValues: {
            email: "",
            full_name: "",
            designation: "",
            company: "",
            phone: "",
            region: "", // This value is linked to Select input
        },
        validationSchema,
        onSubmit: async (values) => {
            try {
                await SignUpSuperAdmin(values);
                formik.resetForm();

            } catch (error) {
                console.error(error);
            }
        },
    });


    const SignUpSuperAdmin = async (values) => {
        setLoadingUser(true);
        try {

            // console.log("Sign-Up values", values);
            
            toast.success("Company added successfully");
            setTimeout(() => {
                handleClose();
            }, 1000);
        } catch (error) {
            setLoadingUser(false);
            toast.error("Failed to add company");
        }finally{
            setLoadingUser(false);
        }
    };

    // Handle phone number change and validation
    const handlePhoneChange = (e) => {
        const { value } = e.target;
        if (/^\d*$/.test(value) && value.length <= 10) {
            formik.setFieldValue("phone", value);
        }
    };

    // Create region options for Select component
    const options = getState.map((value) => ({
        label: value.region_name,
        value: value.region_name,
    }));

    return (
        <div className="h-full w-1/4 h-1446px popup-form popup-form-dashboard add-user-popup">
            <button
                className="absolute top-4 right-4 p-2 text-gray-600"
                onClick={handleClose}
            >
                <img src={Image} alt="close" />
            </button>
            <form onSubmit={formik.handleSubmit}>
                <div className="add-user-header">
                    <h1>Add Company</h1>
                    <p>Add a New Company</p>
                </div>
                <hr className="hrLine" />
                <div className="add-user-body-superadmin">
                    {/* Email Field */}
                    <div className="form-field">
                        <input
                            type="email"
                            name="email"
                            placeholder="Business Email *"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoComplete="new-email"
                            id="email"
                        />
                        {formik.touched.email && formik.errors.email && (
                            <p className="required-text">{formik.errors.email}</p>
                        )}
                    </div>

                    {/* Phone Field */}
                    <div className="form-field">
                        <input
                            type="text"
                            name="phone"
                            value={formik.values.phone}
                            onChange={handlePhoneChange}
                            onBlur={formik.handleBlur}
                            placeholder="Business Phone *"
                            autoComplete="off"
                            id="phone"
                        />
                        {formik.touched.phone && formik.errors.phone && (
                            <p className="required-text">{formik.errors.phone}</p>
                        )}
                    </div>

                    {/* Full Name Field */}
                    <div className="form-field">
                        <input
                            type="text"
                            name="full_name"
                            value={formik.values.full_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="Full Name *"
                            autoComplete="off"
                            id="full_name"
                        />
                        {formik.touched.full_name && formik.errors.full_name && (
                            <p className="required-text">{formik.errors.full_name}</p>
                        )}
                    </div>

                    {/* Designation Field */}
                    <div className="form-field">
                        <input
                            type="text"
                            name="designation"
                            value={formik.values.designation}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="Designation *"
                            autoComplete="off"
                            id="designation"
                        />
                        {formik.touched.designation && formik.errors.designation && (
                            <p className="required-text">{formik.errors.designation}</p>
                        )}
                    </div>

                    {/* Company Field */}
                    <div className="form-field">
                        <input
                            type="text"
                            name="company"
                            value={formik.values.company}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="Company *"
                        />
                        {formik.touched.company && formik.errors.company && (
                            <p className="required-text">{formik.errors.company}</p>
                        )}
                    </div>

                    {/* Region Select */}
                    <div className="form-field">
                        <Select
                            showSearch
                            style={{ width: "100%", fontSize: 10 }}
                            placeholder="Search to Select"
                            options={options}
                            onChange={(value) => formik.setFieldValue("region", value)}
                            value={formik.values.region || undefined}
                        />
                        {formik.touched.region && formik.errors.region && (
                            <p className="required-text">{formik.errors.region}</p>
                        )}
                    </div>
                </div>

                {/* Footer */}
                <div className="add-user-footer">
                    <button
                        type="button"
                        className="reset-btn"
                        onClick={() => formik.resetForm()}
                    >
                        Reset
                    </button>
                    <Spin spinning={loadingUser}>
                        <button type="submit" className="submit-btn">
                            Submit
                        </button>
                    </Spin>
                </div>
            </form>
        </div>
    );
};

export default AddCompany;
