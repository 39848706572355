import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Image from "../../../Assets/Images/close.png";
import { addUser } from "../../../Services/authHeader";
import { Spin } from "antd";

const AddUser = ({ handleClose, userCompany }) => {
  const [loadingUser, setloadingUser] = useState(false)

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Please enter your email")
      .email("Please enter a valid email address")
      .matches(
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        "Please enter a valid email address"
      ),
    first_name: Yup.string().required("Enter your first name"),
    company: Yup.string().required("Enter your company name"),
    phone: Yup.string()
      .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
      .required("Please enter your mobile number"),
    // password: Yup.string().required("Please enter password"),
  });
  document.getElementById("dark-mode-on").classList.add("dark-mode-on");

  const formik = useFormik({
    initialValues: {
      email: "",
      first_name: "",
      company: userCompany,
      phone: "",
      // password: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      await addReviewUser(values);
      formik.resetForm();
      handleClose();
    },
  });

  const addReviewUser = async (values) => {
    setloadingUser(true)
    try {
      const newUser = await addUser(values);
      // setUserList((prevList) => [...prevList, newUser]);
      setloadingUser(false)
      toast.success("User added successfully");
    } catch (error) {
      if (error?.response?.data?.exception?.includes("DuplicateEntryError")) {

        toast.error("User already exists.");
      } else {
        if (error?.response?.data?.exception.includes("User with this phone no. already exist.")) {

          toast.error("Phone no. already exist");
        }else if(error?.response?.data?.exception?.includes("Duplicate entry")){
          toast.error("Phone no. already exist");
        }
         else {
          toast.error("Failed to add user");
        }
      }
    } finally {
      setloadingUser(false)
    }
  };

  const handlePhoneChange = (e) => {
    const { value } = e.target;
    if (/^\d*$/.test(value) && value.length <= 10) {
      formik.setFieldValue("phone", value);
    }
  };

  return (
    <div className="h-full w-1/4 h-1446px popup-form popup-form-dashboard add-user-popup">
      <button
        className="absolute top-4 right-4 p-2 text-gray-600"
        onClick={handleClose}
      >
        <img src={Image} alt="close" />
      </button>
      <form onSubmit={formik.handleSubmit}>
        <div className="add-user-header">
          <h1>Add User</h1>
          <p>Add a New User</p>
        </div>
        <hr className="hrLine" />
        <div className="add-user-body">
          <div className="form-field">
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              autoComplete="new-email"
              id="email"
            />
            {formik.touched.email && formik.errors.email && (
              <p className="required-text">{formik.errors.email}</p>
            )}
          </div>
          <div className="form-field">
            <input
              type="text"
              name="first_name"
              value={formik.values.first_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Name"
              autoComplete="off"
              id="first_name"
            />
            {formik.touched.first_name && formik.errors.first_name && (
              <p className="required-text">{formik.errors.first_name}</p>
            )}
          </div>
          {/* <div className="form-field">
            <input
              type="text"
              name="company"
              value={formik.values.company}
              // onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Company"
              className=" "
            />
            {formik.touched.company && formik.errors.company && (
              <p className="required-text">{formik.errors.company}</p>
            )}
          </div> */}
          <div className="form-field">
            <input
              type="text"
              name="phone"
              value={formik.values.phone}
              onChange={handlePhoneChange}
              onBlur={formik.handleBlur}
              placeholder="Phone"
              autoComplete="off"
              id="phone"
            />
            {formik.touched.phone && formik.errors.phone && (
              <p className="required-text">{formik.errors.phone}</p>
            )}
          </div>

          {/* <div className="form-field">
            <input
              type="password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="*****"
              autoComplete="new-password"
              id="password"
            />
            {formik.touched.password && formik.errors.password && (
              <p className="required-text">{formik.errors.password}</p>
            )}
          </div> */}

        </div>
        <div className="add-user-footer">
          <button
            type="button"
            className="reset-btn"
            onClick={() => formik.resetForm()}
          >
            Reset
          </button>
          <Spin spinning={loadingUser}>
            <button type="submit" className="submit-btn">
              Submit
            </button>
          </Spin>
        </div>
      </form>
    </div>
  );
};

export default AddUser;
